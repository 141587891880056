<template>
    <vx-card title="Subscriptions" :subtitle="subTitle" class="no-over">
        <div class="vx-row">
            <div class="vx-col w-full">
                <div class="p-6 flex justify-center text-center">
                    <div>
                        <div class="mb-3">
                            <feather-icon
                                icon="ShieldIcon"
                                class="p-3 inline-flex rounded-full text-success"
                                :style="{
                                    background: `rgba(var(--vs-success),.15)`,
                                }"
                            ></feather-icon>
                        </div>
                        <div class="mb-3">
                            <h2 class="mb-1 font-bold">
                                {{ subscriptions.totalSubscriptions }}
                            </h2>
                        </div>
                        <div class="mb-3">
                            <span>{{ percentage }}%</span>
                        </div>
                        <div class="mb-3">
                            <h2 class="mb-1 font-bold"></h2>
                            <span>Total Subscriptions</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <vs-divider></vs-divider>

        <div class="vx-row">
            <div class="vx-col w-full">
                <DatePicker mode="range" v-model="range"></DatePicker>
            </div>
        </div>
    </vx-card>
</template>

<script>
import { http } from '@/services'

import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
    name: 'SubscriptionsReportCard',
    components: {
        DatePicker,
    },
    mounted() {
        this.subscriptionsReportFetch()
    },
    computed: {
        subTitle() {
            if (this.updated !== null && this.range.start && this.range.end)
                return `${this.range.start.toDateString()} to ${this.range.end.toDateString()}`
            return 'Last 28 Days'
        },
        percentage() {
            if (this.subscriptions.totalWills)
                return (
                    (this.subscriptions.totalSubscriptions /
                        this.subscriptions.totalWills) *
                    100
                ).toFixed(0)
            return 0
        },
    },
    watch: {
        range: {
            immediate: true,
            deep: true,
            handler(valueNew, valueOld) {
                if (valueOld) {
                    if (
                        valueNew.start !== valueOld.start &&
                        valueNew.end !== valueOld.end
                    ) {
                        this.subscriptionsReportFetch()
                    }
                }
            },
        },
    },
    data() {
        return {
            updated: null,
            range: {
                start: null,
                end: null,
            },
            subscriptions: {
                totalWills: 0,
                totalSubscriptions: 0,
            },
        }
    },
    methods: {
        subscriptionsReportFetch() {
            http.get('report_subscriptions', {
                params: {
                    start: this.range.start,
                    end: this.range.end,
                },
            })
                .then((response) => {
                    this.updated = true
                    this.subscriptions.totalSubscriptions =
                        response.data.total_subscriptions
                    this.subscriptions.totalWills = response.data.total_wills
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>

<style></style>
