<template>
    <vx-card title="Payments" :subtitle="subTitle" class="no-over">
        <div class="vx-row">
            <div class="vx-col w-full">
                <div class="p-6 flex justify-center text-center">
                    <div>
                        <div class="mb-3">
                            <feather-icon
                                icon="DollarSignIcon"
                                class="p-3 inline-flex rounded-full text-success"
                                :style="{
                                    background: `rgba(var(--vs-success),.15)`,
                                }"
                            ></feather-icon>
                        </div>
                        <div class="mb-3">
                            <h2 class="mb-1 font-bold">£{{ total / 100 }}</h2>
                        </div>

                        <div class="mb-3">
                            <p class="mb-1">
                                Invoices £{{ payments.invoices / 100 }}
                            </p>
                        </div>
                        <div class="mb-3">
                            <p class="mb-1">
                                Subscriptions £{{
                                    payments.subscriptions / 100
                                }}
                            </p>
                        </div>
                        <div class="mb-3">
                            <p class="mb-1">
                                Wills £{{ payments.wills / 100 }}
                            </p>
                        </div>
                        <div class="mb-3">
                            <p class="mb-1">
                                Printing £{{ payments.printing / 100 }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <vs-divider></vs-divider>
        <div class="vx-row">
            <div class="vx-col w-full">
                <DatePicker mode="range" v-model="range"></DatePicker>
            </div>
        </div>
    </vx-card>
</template>

<script>
import { http } from '@/services'

import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
    name: 'PaymentsReportCard',
    components: {
        DatePicker,
    },
    mounted() {
        this.paymentsReportFetch()
    },
    watch: {
        range: {
            immediate: true,
            deep: true,
            handler(value) {
                if (value.start && value.end) {
                    this.paymentsReportFetch()
                }
            },
        },
    },
    computed: {
        subTitle() {
            if (this.updated !== null && this.range.start && this.range.end)
                return `${this.range.start.toDateString()} to ${this.range.end.toDateString()}`
            return 'Last 28 Days'
        },
        total() {
            return (
                this.payments.invoices +
                this.payments.subscriptions +
                this.payments.wills +
                this.payments.printing
            )
        },
    },
    data() {
        return {
            updated: null,
            range: {
                start: null,
                end: null,
            },
            payments: {
                invoices: 0,
                wills: 0,
                printing: 0,
                subscriptions: 0,
            },
        }
    },
    methods: {
        paymentsReportFetch() {
            http.get('report_payments', {
                params: {
                    start: this.range.start,
                    end: this.range.end,
                },
            })
                .then((response) => {
                    this.updated = true
                    this.payments.invoices = response.data.invoices
                    this.payments.printing = response.data.printing
                    this.payments.wills = response.data.wills
                    this.payments.subscriptions = response.data.subscriptions
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>

<style></style>
