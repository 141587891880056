<template>
    <vx-card
        title="Users-Wills Conversion"
        :subtitle="subTitle"
        class="no-over"
    >
        <div class="vx-row">
            <div class="vx-col w-full">
                <div class="p-6 flex justify-center text-center">
                    <div>
                        <div class="mb-3">
                            <feather-icon
                                icon="FileTextIcon"
                                class="p-3 inline-flex rounded-full text-success"
                                :style="{
                                    background: `rgba(var(--vs-success),.15)`,
                                }"
                            ></feather-icon>
                        </div>
                        <div class="mb-3">
                            <h2 class="mb-1 font-bold">{{ percentage }}%</h2>
                        </div>
                        <div class="mb-3">
                            <span>{{ conversion.totalUsers }} Users</span>
                        </div>
                        <div class="mb-3">
                            <span>{{ conversion.usersWithWills }} Wills</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <vs-divider></vs-divider>
        <div class="vx-row">
            <div class="vx-col w-full">
                <DatePicker mode="range" v-model="range"></DatePicker>
            </div>
        </div>
    </vx-card>
</template>

<script>
import { http } from '@/services'

import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
    name: 'ConversionReportCard',
    components: {
        DatePicker,
    },
    mounted() {
        this.conversionReportFetch()
    },
    watch: {
        range: {
            immediate: true,
            deep: true,
            handler(valueNew, valueOld) {
                if (valueOld) {
                    if (
                        valueNew.start !== valueOld.start &&
                        valueNew.end !== valueOld.end
                    ) {
                        this.conversionReportFetch()
                    }
                }
            },
        },
    },
    computed: {
        subTitle() {
            if (this.updated !== null && this.range.start && this.range.end)
                return `${this.range.start.toDateString()} to ${this.range.end.toDateString()}`
            return 'Last 28 Days'
        },
        percentage() {
            if (this.conversion.totalUsers)
                return (
                    (this.conversion.usersWithWills /
                        this.conversion.totalUsers) *
                    100
                ).toFixed(1)
            return 0
        },
    },

    methods: {
        conversionReportFetch() {
            http.get('report_conversion', {
                params: {
                    start: this.range.start,
                    end: this.range.end,
                },
            })
                .then((response) => {
                    this.updated = true
                    this.conversion.totalUsers = response.data.total_users
                    this.conversion.usersWithWills =
                        response.data.users_with_wills
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>

<style></style>
