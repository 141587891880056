<template>
    <div id="willOverview">
        <div class="vx-row">
            <div
                class="vx-col w-full sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/4 mb-base"
            >
                <SubscriptionReportCard />
            </div>
            <div
                class="vx-col w-full sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/4 mb-base"
            >
                <PrintingReportCard />
            </div>
            <div
                class="vx-col w-full sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/4 mb-base"
            >
                <PaymentsReportCard />
            </div>
            <div
                class="vx-col w-full sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/4 mb-base"
            >
                <ConversionReportCard />
            </div>
        </div>
    </div>
</template>

<script>
import { http, httpGoCardless } from '@/services'
import { textFormat } from '@/mixins/TextFormat'

import SubscriptionReportCard from './Cards/Subscriptions'
import PrintingReportCard from './Cards/Printing'
import PaymentsReportCard from './Cards/Payments'
import ConversionReportCard from './Cards/Conversion'

export default {
    name: 'ReportOverview',
    mixins: [textFormat],
    components: {
        SubscriptionReportCard,
        PrintingReportCard,
        PaymentsReportCard,
        ConversionReportCard,
    },
    props: [],
    created() {
        this.reportDataFetch()
    },
    beforeRouteUpdate(to, from, next) {
        this.reportDataFetch()
        next()
    },
    watch: {},
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.backPath = from.path
        })
    },
    beforeRouteLeave(to, from, next) {
        next()
    },
    computed: {
        subTitle() {
            if (this.fromDate && this.toDate)
                return `${this.fromDate.toDateString()} to ${this.toDate.toDateString()}`
            return 'Last 28 days'
        },
    },
    data() {
        return {
            data: null,
            backPath: null,
            fromDate: null,
            toDate: null,
        }
    },
    methods: {
        reportDataFetch(id) {
            this.$vs.loading()
            var data = {
                from_date: this.fromDate,
                to_date: this.toDate,
            }
            http.get('report_share', { params: data })
                .then((response) => {
                    this.data = response.data
                    this.$vs.loading.close()
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.loading.close()
                })
        },
    },
}
</script>

<style scoped>
.no-over {
    overflow: visible;
}
</style>
