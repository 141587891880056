<template>
    <vx-card title="Printing" :subtitle="subTitle" class="no-over">
        <div class="vx-row">
            <div class="vx-col w-full">
                <div class="p-6 flex justify-center text-center">
                    <div>
                        <div class="mb-3">
                            <feather-icon
                                icon="PrinterIcon"
                                class="p-3 inline-flex rounded-full text-success"
                                :style="{
                                    background: `rgba(var(--vs-success),.15)`,
                                }"
                            ></feather-icon>
                        </div>
                        <div class="mb-3">
                            <h2 class="mb-1 font-bold">
                                {{ printing.totalPrinting }}
                            </h2>
                        </div>
                        <div class="mb-3">
                            <span>{{ percentage }}%</span>
                        </div>
                        <div class="mb-3">
                            <h2 class="mb-1 font-bold"></h2>
                            <span>Total Paid Printing</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <vs-divider></vs-divider>
        <div class="vx-row">
            <div class="vx-col w-full">
                <DatePicker mode="range" v-model="range"></DatePicker>
            </div>
        </div>
    </vx-card>
</template>

<script>
import { http } from '@/services'

import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
    name: 'PrintingReportCard',
    components: {
        DatePicker,
    },
    mounted() {
        this.printingReportFetch()
    },
    watch: {
        range: {
            immediate: true,
            deep: true,
            handler(valueNew, valueOld) {
                if (valueOld) {
                    if (
                        valueNew.start !== valueOld.start &&
                        valueNew.end !== valueOld.end
                    ) {
                        this.printingReportFetch()
                    }
                }
            },
        },
    },
    computed: {
        subTitle() {
            if (this.updated !== null && this.range.start && this.range.end)
                return `${this.range.start.toDateString()} to ${this.range.end.toDateString()}`
            return 'Last 28 Days'
        },
        percentage() {
            if (this.printing.totalWills)
                return (
                    (this.printing.totalPrinting / this.printing.totalWills) *
                    100
                ).toFixed(0)
            return 0
        },
    },
    data() {
        return {
            updated: null,
            range: {
                start: null,
                end: null,
            },
            printing: {
                totalWills: 0,
                totalPrinting: 0,
            },
        }
    },
    methods: {
        printingReportFetch() {
            http.get('report_printing', {
                params: {
                    start: this.range.start,
                    end: this.range.end,
                },
            })
                .then((response) => {
                    this.updated = true
                    this.printing.totalPrinting = response.data.total_printing
                    this.printing.totalWills = response.data.total_wills
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>

<style></style>
